import axios from 'axios';

const ApiGu = axios.create({
    baseURL: process.env.REACT_APP_GU_API,
    headers: {        
        'Content-Type': 'application/json' 
    }
});

export default ApiGu;
