import React, { useContext, useMemo, useState } from "react";
import "./index.css";
import ModalDommus from "../Modal";
import { ModalModulosList } from "../ModalModulosList";
import ModuloIcone from "../ModuloIcone";
import { DommusContext } from "../../contexts/DommusContext";
import { ModulosEnum } from "../../types/ModulosEnum";

export function Principal() {
   const [openModalModulos, setOpenModalModulos] = useState(false);
   const {currentModule, accessModules} = useContext(DommusContext);

   const resolveNomeModulo = ()=>{
      switch(currentModule.id){
         case ModulosEnum.CONECTA:
            return "CONECTA";
         case ModulosEnum.CRM:
            return "PROSPECTA";
         case ModulosEnum.VENDAS:
            return "VENDAS";
         case ModulosEnum.DESK:
            return "DESK";
         default:
            return ""
      }
   }

   return (
      <>
         {accessModules.length ?
            <div className="btn-navegacao"
                  onClick={() => {
                     setOpenModalModulos(true);
                  }}
            >
               <ModuloIcone sigla={currentModule.sigla ?? ""} noBackground={true} />
               <span className="descricao">{resolveNomeModulo()}</span>
            </div>
            :
            <div className="module-name-estatico">
               <ModuloIcone sigla={currentModule.sigla ?? ""} noBackground={true} />
               <span className="descricao">{resolveNomeModulo()}</span>
            </div>
         }
         <ModalDommus
               close={() => {
                  setOpenModalModulos(false);
               }}
               open={openModalModulos}
               titulo={"Módulos"}
         >
               <ModalModulosList />
         </ModalDommus>
      </>
   );
}
