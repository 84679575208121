import React, {Dispatch, createContext, useEffect, useState} from "react";
import { ModuloType } from "../types/ModulosTypes";
import { trackPromise } from "react-promise-tracker";
import { GuService } from "../services/GuService";
import { ModulosEnum } from "../types/ModulosEnum";

interface DommusContextProps{
  guToken: string;
  setGuToken: Dispatch<React.SetStateAction<string>>;
  client: number|string;
  setClient: Dispatch<React.SetStateAction<number|string>>;
  user: number;
  setUser: Dispatch<React.SetStateAction<number>>;
  currentModule: ModuloType;
  setCurrentModule: Dispatch<React.SetStateAction<ModuloType>>
  accessModules: ModuloType[];
  setAccessModules: Dispatch<React.SetStateAction<ModuloType[]>>
  resolverModuloAtual: (moduloAtual:number) => void;
}

export const DommusContext = createContext({} as DommusContextProps);

export function DommusProvider ({children}: React.PropsWithChildren) {

  const [guToken, setGuToken] = useState<string>("");
  const [user, setUser] = useState<number>(0);
  const [client, setClient] = useState<number | string>("");
  const [currentModule, setCurrentModule] = useState<ModuloType>({} as ModuloType);
  const [accessModules, setAccessModules] = useState<ModuloType[]>([] as ModuloType[]);

  useEffect(()=>{
    if(user && client){
      buscarModulosAcesso();
    }
  },[user, client]);

  const resolverModuloAtual = (moduloAtual:number)=>{
    let temp = accessModules.find((modulo)=>{
      return modulo.id === moduloAtual;
    })
    if(temp){
      setCurrentModule(temp);
    }else{
      switch (moduloAtual) {
        case ModulosEnum.CONECTA:
          setCurrentModule({
            id: 6,
            nome: "CONECTA",
            sigla: "CA"
          });
          break;
        case ModulosEnum.CRM:
          setCurrentModule({
            id: 1,
            nome: "PROSPECTA",
            sigla: "CRM"
          });
          break;
        case ModulosEnum.DESK:
          setCurrentModule({
            id: 9,
            nome: "DESK",
            sigla: "DK"
          });
          break;
        case ModulosEnum.VENDAS:
            setCurrentModule({
              id: 2,
              nome: "VENDAS",
              sigla: "VE"
            });
          break;
        default:
          break;
      }
    }
  }

  const buscarModulosAcesso = ()=>{
      trackPromise(GuService.buscarModulosAcesso(guToken, client, user)).then((response)=>{
        setAccessModules(response.data);
      }).catch((erro)=>{
        setAccessModules([]);
      })
  }

  return (
    <DommusContext.Provider value={{
        guToken, setGuToken,
        user, setUser,
        client, setClient,
        currentModule, setCurrentModule,
        accessModules, setAccessModules,
        resolverModuloAtual
      }}
    >
      {children}
    </DommusContext.Provider>
  ) 
}
