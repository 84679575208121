import ApiGu from "./ApiGu";

export const GuService = {
    buscarModulosAcesso: (
        token: string,
        cliente: number | string,
        usuario: number
    ) => {
        return ApiGu.get(`/cliente/${cliente}/usuario/${usuario}/modulo`, {
            headers: {
                Authorization: token,
            },
        });
    },
    acessarModulo: (token: string, idModulo: number) => {
        return ApiGu.get(`/cliente-acessar-modulo/${idModulo}`, {
            headers: {
                Authorization: token,
            },
        });
    },
};
