import React, { useContext } from "react";
import "./index.css"
import { DommusContext } from "../../contexts/DommusContext";
import { CardModulo } from "./CardModulo";
import { Container, Row } from "react-bootstrap";

export function ModalModulosList() {
    const { accessModules } = useContext(DommusContext);

    return (
        <Container fluid className="modal-modulos-list pt-3">
            <Row className="card-modules-section">
                {accessModules.map((module, index) => {
                    return <CardModulo key={index} modulo={module} />
                })}
            </Row>
        </Container>
    )
}