import React from "react";
import { createRoot } from 'react-dom/client';
import ReactDOM from "react-dom/client";
import App from "./App";
import { AppContextProvider } from "./contexts/AppContext";
const wrapper = document.getElementById('dommus-navegacao-modulo-shadow')?.shadowRoot || document;
const container = wrapper.getElementById('dommus-navegacao-modulo');
const root = createRoot(container);
const guToken = container.getAttribute("data-gu-token");
const user = container.getAttribute("data-user");
const client = container.getAttribute('data-client');
const currentModule = container.getAttribute('data-current-module');

root.render(
    <AppContextProvider>
        <App token={guToken} 
            user={user} 
            client={client} 
            currentModule={currentModule}
        />
    </AppContextProvider>
);
