import React, { useContext, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import "./fa-styles.css";
import "./swal-styles.css";
import { Principal } from "./components/Principal";
import { DommusContext } from "./contexts/DommusContext";

function App({token, user, client, currentModule}) {    
    const {accessModules, resolverModuloAtual, setGuToken, setUser, setClient} = useContext(DommusContext);

    useEffect(()=>{
        setUser(parseInt(user) ?? 0);
        let clientParse = parseInt(client);
        if(clientParse){
            setClient(clientParse);        
        }else{
            setClient(client)
        }
        setGuToken(token);
    },[])

    useEffect(()=>{
        resolverModuloAtual(parseInt(currentModule) ?? 0);
    },[accessModules])

    return (
        <div className="dommus-navegacao-modulos-app">
            <Principal />
        </div>        
    );
}

export default App;
