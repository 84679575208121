import React, { ReactElement, createContext } from "react";
import { DommusProvider } from "./DommusContext";

interface AppContextProps{
}

export const AppContext = createContext({} as AppContextProps);

export function AppContextProvider({children}: React.PropsWithChildren) {
    return (
        <AppContext.Provider value={{}}>
            <DommusProvider>{children}</DommusProvider>
        </AppContext.Provider>
    );
}
